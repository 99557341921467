<template>
  <div class="controllerHuxi">
    <div class="controller-modules-header left">循环系统</div>
    <button class="controller-btn" @click="submit('check')">检查电路</button>
    <div class="controller-modules-header left">呼吸模式</div>
    <div class="controller-checkbox">
      <a-radio-group v-model:value="checkValue" style="width:100%;">
        <a-row>
          <a-col :span="8" v-for="(item,index) in huxiList" :key="index">
            <a-radio :value="item.type">{{item.name}}</a-radio>
          </a-col>
        </a-row>
      </a-radio-group>
    </div>
    <button class="controller-btn no-margin-top" :disabled='breatheDis'  :style="breatheDis == true ?'background: #ccc':''" @click="submit('breathe')">
      确认
    </button>
    <div class="controller-modules-header left">设置</div>
    <div class="selectForm">
      <div class="item left">
        <h4 style="width:138px !important">潮气量</h4>
        <a-select :disabled='showFalse' v-model:value="form.selectValueV1" placeholder="请选择" style="width: calc(100% - 138px - 54px);">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option :value="item" v-for="(item,index) in huxichaoliangList" :key="index">{{item}}</a-select-option>
        </a-select>
        mL
      </div>
      <div class="item left">
        <h4 style="width:138px !important">呼吸频率</h4>
        <a-select  v-model:value="form.selectValueV2" placeholder="请选择" style="width: calc(100% - 138px - 54px);">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option :value="item" v-for="(item,index) in huxihuifulvList" :key="index">{{item}}</a-select-option>
        </a-select>
        BPM
      </div>
      <div class="item left">
        <h4 style="width:138px !important">PEEP呼气终末正压</h4>
        <a-select :disabled='showFalse'  v-model:value="form.selectValueV3" placeholder="请选择" style="width: calc(100% - 138px - 54px);">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option :value="item" v-for="(item,index) in huxikuishiList" :key="index">{{item}}</a-select-option>
        </a-select>
        cm H2O
      </div>
    </div>
    <button class="controller-btn no-margin-top" :disabled='nerveDis' :style="nerveDis == true ?'background: #ccc':''" @click="submit('nerve')">
      确认
    </button>
    <div class="controller-modules-header left">操作记录</div>
    <div class="controller-modules-log">
       <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
        【{{formateSeconds(item.logTime)}}】{{item.logContent}}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item" >
            暂无操作记录。
        </div>
      </block>
    </div>
  </div>
</template>
<script>
import {huxiList,huxichaoliangList,huxihuifulvList,huxikuishiList} from '@/utils/data'
import { mapGetters, mapActions } from "vuex";
import  {formateSeconds} from '@/utils/tool'
export default {
  data() {
    return {
      huxichaoliangList,
      huxihuifulvList,
      huxikuishiList,
      huxiList,
      checkValue: "",
      showFalse: false,
      breatheDis: false,
      breatheDiss: false,
      nerveDis: false,
      nerveDiss: false,
      form: {
        selectValueV1: undefined,
        selectValueV1Shu: undefined,
        selectValueV1Tui: undefined,
      },
      formateSeconds,
      actionLog:[],
    };
  },
  created(){
    this.getActionLog();
  },
  mounted(){
    if (this.checkValue == '' && this.breatheDis!=true) {
      this.breatheDis=true
    }
  },
  watch:{
    checkValue:{
     handler(n){
      if (n == '') {
        this.breatheDis=true
      }else  if (n != '' && this.breatheDiss!=true) {
        this.breatheDis=false
      }else  if (n != '' && this.breatheDiss==true) {
        this.breatheDis=true
      }
      if (n=='breathing_spontaneous') {
          this.showFalse=true
          this.form.selectValueV1=undefined
          this.form.selectValueV3=undefined
      }else{
          this.showFalse=false
      }
     } 
    },
    form:{
     handler(n){
      console.log(n.selectValueV1,n.selectValueV2,n.selectValueV3,'12312312');

      if (n.selectValueV1!=undefined&&n.selectValueV2!=undefined&&n.selectValueV3!=undefined && this.nerveDiss==false) {
        this.nerveDis=false
      }else if (n.selectValueV1==undefined&&n.selectValueV2==undefined&&n.selectValueV3==undefined) {
        this.nerveDis=true
      }
      // else  if (n != '' && this.breatheDiss!=true) {
      //   this.breatheDis=false
      // }else  if (n != '' && this.breatheDiss==true) {
      //   this.breatheDis=true
      // }
      // if (n=='breathing_spontaneous') {
      //     this.showFalse=true
      //     this.form.selectValueV1=undefined
      //     this.form.selectValueV3=undefined
      // }else{
      //     this.showFalse=false
      // }
     } ,
     immediate: true,
    deep:true
    }
  },
  methods: {
     ...mapActions({
      submitStep: "exma/submitStep",
    }),
    /**
     * check 检查电路
     * breathe 呼吸方式 checkValue
     * nerve 神经刺激 form
     */
    submit(type) {
      let d = {
        memberCaseId: this.memberCaseId,
        // component: 'labs',
        actionTime: this.getHandleTimeAt,
        // otherData: {
        //   labs:this.checkValue
        // },
      };
      if(type == 'check'){
        d['component'] = 'check_circuit'
        this.submitStep(d).then(res=>{
        this.getActionLog();
      });
      }
      if(type == 'breathe'){
        if(!this.checkValue)return;
        d['component'] = this.checkValue
        this.submitStep(d).then(res=>{
          if (res == 1) {
            this.breatheDiss=true
            this.breatheDis=true
          }
        this.getActionLog();
      });
      }
      if(type == 'nerve'){
        d['component'] = 'spontaneous_ventilation';
        d['otherData'] = {
          chaoliang:this.form.selectValueV1,
          huifulv:this.form.selectValueV2,
          kuishi:this.form.selectValueV3,
        }
        this.submitStep(d).then(res=>{
          if (res == 1) {
            this.nerveDis=true
            this.nerveDiss=true
          }
        this.getActionLog();
      });
      }
      
    },
    async getActionLog(){
      try{
        let res = await this.$http.post(this.$interface.examActionLog,{
          componentCategory:'breathing',
          memberCaseId:this.memberCaseId,
        })
        this.actionLog = res.data.data || [];
      }catch(err){}
    }
  },
  computed: {
    ...mapGetters(["getHandleTimeAt", "memberCaseId"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
<style scoped>
.bac{background: #ccc}
</style>